import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, NavigateFunction, useLocation } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import {
  INVENTORY_PAGES_URLS,
  CLIENT_PAGES_URLS,
  FACILITY_SETTINGS_PAGES_URLS,
  GROUND_CONTROL_PAGES_URLS,
  DELIVERY_PAGES_URLS,
} from 'common/pages';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { useGroundControlStore } from '../../store/GroundControl/groundControlLevelStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { pageHeaderStyles } from './styles';

const logPrefix = getLogPrefixForType('COMPONENT', 'PageHeaderBreadcrumbs');

export const PageHeaderBreadcrumbs = (props: {
  title: string;
  systemId: string;
  navigate: NavigateFunction;
  facilityName: string;
  isReportPage: boolean;
  multipleFacilitiesAccess: boolean;
  multipleFlightDomains: boolean;
}) => {
  const {
    title,
    systemId,
    navigate,
    facilityName,
    isReportPage,
    multipleFacilitiesAccess,
    multipleFlightDomains,
  } = props;

  const { classes } = pageHeaderStyles();
  const location = useLocation();

  const { stateGroundControl } = useGroundControlStore();

  const pathnames = location.pathname.split('/').filter((x) => x);
  pathnames.shift();

  const flight_domain_name = useFacilityLevelStore().stateFacilityLevel.flightDomains.find(
    (fd) => fd.flight_domain_id === pathnames[1],
  )?.flight_domain_name;

  const breadcrumbLabels = (flightDomainId: string) => ({
    [DELIVERY_PAGES_URLS(flightDomainId).INDEX]: multipleFacilitiesAccess ? facilityName : 'Home',
    [DELIVERY_PAGES_URLS(flightDomainId).FD]: flight_domain_name || 'Flight Domain',
    [DELIVERY_PAGES_URLS(flightDomainId).COMMISSIONING]: 'Commissioning',
    [DELIVERY_PAGES_URLS(flightDomainId).FLEET]: 'Fleet',
    [DELIVERY_PAGES_URLS(flightDomainId).DRONE_ZONES]: 'Drone zones',

    [INVENTORY_PAGES_URLS.OVERVIEW]: multipleFacilitiesAccess ? facilityName : 'Home',
    [INVENTORY_PAGES_URLS.LOCATIONS_MANAGEMENT]: 'Locations management',
    [INVENTORY_PAGES_URLS.WAREHOUSE_STATUS]: 'Warehouse status',
    [INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_X]: 'Warehouse status',
    [INVENTORY_PAGES_URLS.REPORTS]: 'Reports',
    [INVENTORY_PAGES_URLS.ANALYTICS]: 'Analytics',
    [INVENTORY_PAGES_URLS.SCHEDULING]: 'Scheduling',
    [INVENTORY_PAGES_URLS.WAREHOUSE_DATA_UPLOADS]: 'Warehouse Data Uploads',
    [FACILITY_SETTINGS_PAGES_URLS.ROOT]: 'Admin',
    [FACILITY_SETTINGS_PAGES_URLS.SYSTEM_DETAILS]: 'System Details',
    [FACILITY_SETTINGS_PAGES_URLS.FACILITY_DETAILS]: 'Facility Details',
    [FACILITY_SETTINGS_PAGES_URLS.FACILITY_SETTINGS]: 'Facility Settings',
    [FACILITY_SETTINGS_PAGES_URLS.UPLOADS]: 'Uploads',
    [FACILITY_SETTINGS_PAGES_URLS.USERS]: 'Users',

    [GROUND_CONTROL_PAGES_URLS(flightDomainId).INDEX]: multipleFacilitiesAccess
      ? facilityName
      : 'Home',
    [GROUND_CONTROL_PAGES_URLS(flightDomainId).OVERVIEW]: multipleFlightDomains
      ? stateGroundControl.flightDomain.flight_domain_name
      : 'Ground Control',
    [GROUND_CONTROL_PAGES_URLS(flightDomainId).FLEET]: 'Fleet',
    [GROUND_CONTROL_PAGES_URLS(flightDomainId).NO_FLY_ZONES]: 'No-fly zones',
    [GROUND_CONTROL_PAGES_URLS(flightDomainId).DRONE_ZONES]: 'Drone zones',
    [GROUND_CONTROL_PAGES_URLS(flightDomainId).SCHEDULE]: 'Schedule',
  });

  return (
    <Breadcrumbs
      data-testid="c-breadcrumb"
      aria-label="breadcrumb"
      sx={{
        display: !facilityName ? 'none' : 'block',
      }}
    >
      {multipleFacilitiesAccess && (
        <Link to={CLIENT_PAGES_URLS.SELECT_FACILITY} className={classes.breadcrumbLink}>
          Home
        </Link>
      )}
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        let label = breadcrumbLabels(stateGroundControl.flightDomain.flight_domain_id)[to];
        if (pathnames[0] === 'commissioning') {
          label = breadcrumbLabels(pathnames[1])[to];
        }

        return last ? (
          <Typography className={classes.disabledLink} color="text.primary" key={to}>
            {label || (isReportPage && title)}
          </Typography>
        ) : (
          <Typography
            className={classes.breadcrumbLink}
            onClick={() => {
              // FIXME: do a proper re-direct when navigate to the "Reports" element of the breadcrumb
              // when on a specific Report page en 2022/06/09
              if (to === '/inventory/reports' && isReportPage) {
                // If the loadSince exists in the state, then navigates back to the
                // filtered Reports page, otherwise re-route to the Reports page
                // with default filter. This will prevent clicking on the Reports breadcrumb link
                // to navigate back to the blank page in case of para-shooting
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                console.debug(logPrefix, 'loadSince', (location.state as any)?.loadSince);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((location.state as any)?.loadSince) {
                  navigate(-1);
                } else {
                  navigate(`/${systemId}${INVENTORY_PAGES_URLS.REPORTS}`);
                }
              } else {
                navigate(`/${systemId}${to}`);
              }
            }}
            key={to}
          >
            {label}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};
