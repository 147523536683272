import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { IconButton } from '@mui/material';
import { DroneIcon } from 'components/common/FacilityMenu/Icons/DroneIcon';
import { useQuery } from '@tanstack/react-query';
import { QueryNames } from 'ts-types/QueryNames';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { LocationHistoryItem } from '../utils/transformLocationHistory';
import { getReportFromLocation } from '../api/getReportFromLocation';
import { fixDateFormat } from '../utils/fixDateFormat';

export const useGetTimeLineIcon = (
  item: LocationHistoryItem,
  systemId: string,
  slotLabel: string,
  version?: number,
): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const { refetch: getReportId } = useQuery({
    queryKey: [QueryNames.LOCATION_HISTORY_GET_REPORT_FROM_LOCATION, systemId, slotLabel, version],
    queryFn: () => getReportFromLocation(systemId, slotLabel, version),
    select: ({ data }) => data.report_id,
    enabled: false,
    retry: false,
  });

  const handleOpenReport = () => {
    getReportId().then(({ data }) => {
      const reportId = data;
      if (reportId) {
        const reportUrl = `/${systemId}${
          INVENTORY_PAGES_URLS.REPORTS
        }/${reportId}?location=${slotLabel}`;

        window.open(reportUrl, '_blank');
      } else {
        enqueueSnackbar('Could not get Report Id.', {
          variant: 'error',
        });
      }
    });
  };

  const timeStamp = moment(item.updatedAt);
  const from = fixDateFormat(timeStamp.subtract(1, 'day').startOf('day').toISOString());
  const until = fixDateFormat(timeStamp.add(2, 'day').endOf('day').toISOString());

  const handleOpenWMS = () => {
    if (item.snapshotId) {
      const wmsUploadUrl = `/${systemId}${
        INVENTORY_PAGES_URLS.WAREHOUSE_DATA_UPLOADS
      }?loadSince=by+interval&searchTerm=${encodeURIComponent(
        item.snapshotId.toLowerCase(),
      )}&from=${from}&until=${until}`;
      window.open(wmsUploadUrl, '_blank');
    }
  };

  switch (item.type) {
    case 'WMS_UPDATE':
      return (
        <IconButton onClick={handleOpenWMS} sx={{ p: 0 }}>
          <WarehouseIcon fontSize="small" sx={{ color: '#424242' }} />
        </IconButton>
      );

    case 'DRONE_REPORT':
      return (
        <IconButton onClick={handleOpenReport} sx={{ p: 0 }}>
          <DroneIcon fontSize="20px" color="#424242" />
        </IconButton>
      );
    case 'EXCLUSION_STATUS_UPDATE':
      return item.exclusion_status === 'EXCLUDE' ? (
        <LocationOffIcon fontSize="small" sx={{ color: '#424242' }} />
      ) : (
        <LocationOnIcon fontSize="small" sx={{ color: '#424242' }} />
      );

    default:
      return <> </>;
  }
};
