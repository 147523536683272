import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { useParams } from 'react-router-dom';
import { REPORT_ACTIONS } from 'common/Actions/actionTypes';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { getReportsTabsMappings } from 'common/Tables';
import { FacilityModalsActionTypes } from 'store/Modals/types';
import { useSettingsStore } from 'store/Settings/settingsStore';
import { TableTabData, TabDataParams } from './TabData';
import { REPORTS } from './helpers';

/**
 * Get tab data function
 * @param param0 TabData params
 * @returns tab data based on active tab index
 */
export const useGetTabData = ({
  reportsToReview,
  reportsFinished,
  reportsOngoing,
  reportsScheduled,
  reportsArchived,
  bulkHandleReports,
}: TabDataParams): { [key: string]: TableTabData } => {
  const {
    REPORTS_ARCHIVED_COLUMNS,
    REPORTS_SCHEDULED_COLUMNS,
    REPORTS_FINISHED_COLUMNS,
    REPORTS_ONGOING_COLUMNS,
    REPORTS_TO_REVIEW_COLUMNS,
    REPORTS_TABLE_TABS,
  } = useSettingsStore();

  const { ongoingTab, finishedTab, scheduledTab, toReviewTab, archivedTab } =
    getReportsTabsMappings(REPORTS_TABLE_TABS);

  const { systemId } = useParams();

  const tabData = {
    [ongoingTab]: {
      tableFor: REPORTS.ONGOING.TABLE_FOR,
      tableTitle: REPORTS.ONGOING.TABLE_TITLE,
      tableSubtitle: REPORTS.ONGOING.TABLE_SUBTITLE,
      headCells: REPORTS_ONGOING_COLUMNS,
      rows: [...reportsOngoing],
      redirectTo: `${systemId}${INVENTORY_PAGES_URLS.REPORTS}`,
      toolbarAction: undefined,
    },
    [finishedTab]: {
      tableFor: REPORTS.FINISHED.TABLE_FOR,
      tableTitle: REPORTS.FINISHED.TABLE_TITLE,
      tableSubtitle: REPORTS.FINISHED.TABLE_SUBTITLE,
      headCells: REPORTS_FINISHED_COLUMNS,
      rows: [...reportsFinished],
      redirectTo: `${systemId}${INVENTORY_PAGES_URLS.REPORTS}`,
      toolbarAction: {
        title: 'Archive',
        icon: ArchiveIcon,
        actionLabel: REPORT_ACTIONS.ARCHIVE,
        onClick: (reportIds: string[]) =>
          bulkHandleReports(reportIds, FacilityModalsActionTypes.REPORT_ARCHIVE),
      },
    },
    [scheduledTab]: {
      tableFor: REPORTS.SCHEDULED.TABLE_FOR,
      tableTitle: REPORTS.SCHEDULED.TABLE_TITLE,
      tableSubtitle: REPORTS.SCHEDULED.TABLE_SUBTITLE,
      headCells: REPORTS_SCHEDULED_COLUMNS,
      rows: [...reportsScheduled],
      redirectTo: '',
      toolbarAction: undefined,
    },
    [toReviewTab]: {
      tableFor: REPORTS.REVIEW.TABLE_FOR,
      tableTitle: REPORTS.REVIEW.TABLE_TITLE,
      tableSubtitle: REPORTS.REVIEW.TABLE_SUBTITLE,
      headCells: REPORTS_TO_REVIEW_COLUMNS,
      rows: [...reportsToReview],
      redirectTo: `${systemId}${INVENTORY_PAGES_URLS.REPORTS}`,
      toolbarAction: {
        title: 'Archive',
        icon: ArchiveIcon,
        actionLabel: REPORT_ACTIONS.ARCHIVE,
        onClick: (reportIds: string[]) =>
          bulkHandleReports(reportIds, FacilityModalsActionTypes.REPORT_ARCHIVE),
      },
    },
    [archivedTab]: {
      tableFor: REPORTS.ARCHIVED.TABLE_FOR,
      tableTitle: REPORTS.ARCHIVED.TABLE_TITLE,
      tableSubtitle: REPORTS.ARCHIVED.TABLE_SUBTITLE,
      headCells: REPORTS_ARCHIVED_COLUMNS,
      rows: [...reportsArchived],
      redirectTo: `${systemId}${INVENTORY_PAGES_URLS.REPORTS}`,
      toolbarAction: {
        title: 'Restore',
        icon: UnarchiveIcon,
        actionLabel: REPORT_ACTIONS.RESTORE,
        onClick: (reportIds: string[]) =>
          bulkHandleReports(reportIds, FacilityModalsActionTypes.REPORT_RESTORE),
      },
    },
  };

  return tabData;
};
